import * as React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button, Container, Icon, List } from "semantic-ui-react";
import _ from "lodash";
import {
  createQuestionCategory,
  deleteQuestion,
} from "../graphql/mutations";
import NewQuestionForm from "./NewQuestionForm";
import NewQuestionCategory from "./NewQuestionCategory";
import {listQuestionCategories} from "./customQueries";

function Question() {
  const [questionCategories, setQuestionCategories] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  async function fetchQuestions() {
    try {
      const questionCategoriesData = await API.graphql(
        graphqlOperation(listQuestionCategories)
      );

      const questionCategories =
        questionCategoriesData.data.listQuestionCategorys.items;

      const selectQuestionCategories = _.map(questionCategories, function (q) {
        return { key: q.id, value: q.id, text: q.title, questions: q.question.items };
      });

      console.log("selectQuestionCategories", selectQuestionCategories);
      setQuestionCategories(selectQuestionCategories);
    } catch (err) {
      console.log("error fetching questions", err);
    }
  }

  function addQuestion(question) {
    console.log("addQuestion", question)
    const newCategories = _.map(questionCategories, (category) => {
      if (category.value === question.question_category_id) {
        const newQuestion = {
          id: question.id,
          title: question.title,
          gender: question.gender
        }
         return {...category, questions: [...category.questions, newQuestion]}
      } else {
        return {...category}
      }
    });
    setQuestionCategories(newCategories);
  }

  async function addQuestionCategory(questionCategory) {
    try {
      const newQuestionData = await API.graphql(
        graphqlOperation(createQuestionCategory, {
          input: {
            title: questionCategory,
          },
        })
      );
      console.log("data", newQuestionData);
      const newQuestion = {
        key: newQuestionData.data.createQuestionCategory.id,
        text: questionCategory,
        value: newQuestionData.data.createQuestionCategory.id,
        questions: []
      }
      console.log("newQuestion", newQuestion)
      setQuestionCategories([...questionCategories, newQuestion])
    } catch (e) {
      console.error("Error", e);
    }
  }

  async function removeQuestion(questionId) {
    console.log("remove question", questionId);
    const newCategories = _.map(questionCategories, (category) => {
        return {...category, questions: _.filter(category.questions, (question) => {
          return question.id !== questionId
          })}
    });
    setQuestionCategories(newCategories);

    try {
      await API.graphql(
        graphqlOperation(deleteQuestion, {
          input: {
            id: questionId,
          },
        })
      );
    } catch (err) {
      console.log("error removing question", err);
    }
  }

  return (
    <Container>
      <h1>Banco de preguntas</h1>

      <NewQuestionCategory onSubmission={addQuestionCategory} />

      <NewQuestionForm
        onSubmission={addQuestion}
        questionCategories={questionCategories}
      />

      {questionCategories.map((questionCategory) => (
        <div key={questionCategory.text}>
          <h1>
            Categoría:
            {questionCategory.text}
          </h1>
          <List bulleted>
            {questionCategory.questions.map((question) => (
              <List.Item key={question.id}>
                {question.title} ({question.gender.join()})
                <Button
                  size="mini"
                  negative
                  onClick={(e) => removeQuestion(question.id)}
                >
                  <Icon name="delete" />
                </Button>
              </List.Item>
            ))}
          </List>
        </div>
      ))}
    </Container>
  );
}

export default Question;
