import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";

function Logout(props) {
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    Auth.signOut()
      .then((c) => {
        console.log("sign out", c);
      })
      .catch((e) => {
        console.error("error", e);
      })
      .finally(() => {
        setStatus("done");
      });
  }, []);

  if (status === "loading") {
    return <div>Cerrando</div>;
  }
  return <Redirect to="/" />;
}

export default Logout;
