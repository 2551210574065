/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestionCategory = /* GraphQL */ `
  query GetQuestionCategory($id: ID!) {
    getQuestionCategory(id: $id) {
      id
      title
      createdAt
      updatedAt
      question {
        nextToken
      }
    }
  }
`;
export const listQuestionCategorys = /* GraphQL */ `
  query ListQuestionCategorys(
    $filter: ModelQuestionCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fullName
      gender
      preferredName
      birthdate
      location
      hobbies
      hasPets
      hasChildren
      hasGrandchildren
      pets {
        name
      }
      children {
        name
        alive
        biological_child
      }
      grandchildren {
        name
        alive
        biological_child
      }
      suscriptors {
        name
        email
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        gender
        preferredName
        birthdate
        location
        hobbies
        hasPets
        hasChildren
        hasGrandchildren
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStory = /* GraphQL */ `
  query GetStory($id: ID!) {
    getStory(id: $id) {
      id
      title
      status
      book_id
      question_id
      week_number
      s3EmailPath
      author_id
      subscribers_ids
      rawText
      formattedText
      imageUrl
      createdAt
      updatedAt
      version
      book {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const listStorys = /* GraphQL */ `
  query ListStorys(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        book_id
        question_id
        week_number
        s3EmailPath
        author_id
        subscribers_ids
        rawText
        formattedText
        imageUrl
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const storiesByAuthor = /* GraphQL */ `
  query StoriesByAuthor(
    $author_id: String
    $week_number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storiesByAuthor(
      author_id: $author_id
      week_number: $week_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        book_id
        question_id
        week_number
        s3EmailPath
        author_id
        subscribers_ids
        rawText
        formattedText
        imageUrl
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      title
      current_week
      author_id
      createdAt
      updatedAt
      stories {
        nextToken
      }
    }
  }
`;
export const booksByAuthor = /* GraphQL */ `
  query BooksByAuthor(
    $author_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    booksByAuthor(
      author_id: $author_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      title
      gender
      question_category_id
      createdAt
      updatedAt
    }
  }
`;
export const getQuestionDelivered = /* GraphQL */ `
  query GetQuestionDelivered($id: ID!) {
    getQuestionDelivered(id: $id) {
      id
      question_id
      email
      message_id
      tracking_code
      book_id
      createdAt
      updatedAt
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const listQuestionDelivereds = /* GraphQL */ `
  query ListQuestionDelivereds(
    $filter: ModelQuestionDeliveredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionDelivereds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question_id
        email
        message_id
        tracking_code
        book_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionDeliveredByMessage = /* GraphQL */ `
  query QuestionDeliveredByMessage(
    $message_id: String
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionDeliveredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionDeliveredByMessage(
      message_id: $message_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question_id
        email
        message_id
        tracking_code
        book_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionHint = /* GraphQL */ `
  query GetQuestionHint($id: ID!) {
    getQuestionHint(id: $id) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionHints = /* GraphQL */ `
  query ListQuestionHints(
    $filter: ModelQuestionHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($email_hash: String!) {
    getInvite(email_hash: $email_hash) {
      author_email
      author_name
      client_id
      email_hash
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $email_hash: String
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvites(
      email_hash: $email_hash
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        author_email
        author_name
        client_id
        email_hash
        accepted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
