export const listQuestionCategories = /* GraphQL */ `
  query ListQuestionCategorys(
    $filter: ModelQuestionCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        createdAt
        updatedAt
        question {
            items {
              id
              title
              gender
            }
        }
      }
      nextToken
    }
  }
`;