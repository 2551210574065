/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:584f7934-7471-48bb-a606-58757eb9bc96",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XpLCCZpgu",
    "aws_user_pools_web_client_id": "7tg8iu5mjo523j2pkqug49a283",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gzzc75eaknamjaeucmkykdod4q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
