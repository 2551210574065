import * as React from "react";
import {Button, Container, Form, Radio} from "semantic-ui-react";
import {useEffect, useState} from "react";
import {Auth, API, graphqlOperation} from "aws-amplify";
import {createUser, updateUser} from "../graphql/mutations";
import {getRegistrationForm} from "./dao";

export default function RegistrationForm() {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("NOT_SELECTED");
  const [preferredName, setPreferredName] = useState("");
  const [location, setLocation] = useState("");
  const [hobbies, setHobbies] = useState("");

  const [hasPets, setHasPets] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [hasGrandchildren, setHasGrandchildren] = useState(false);

  const [suscriptors, setSuscriptors] = useState([{}]);

  const [status, setStatus] = useState('loading')
  const [registrationExists, setRegistrationExists] = useState(false);

  useEffect(() => {
    getRegistrationForm().then((data) => {
      if (data) {
        setFullName(data.fullName);
        setPreferredName(data.preferredName);
        setLocation(data.location)
        setHobbies(data.hobbies)
        setHasPets(data.hasPets)
        setHasChildren(data.hasChildren)
        setHasGrandchildren(data.hasGrandchildren)

        setRegistrationExists(true);
      }
      setStatus('ready')
    })
  }, [])

  async function handleSubmit() {
    setStatus('saving');
    const createOrUpdate = (registrationExists) ? updateUser : createUser;
    API.graphql(graphqlOperation(createOrUpdate, {
      input: {
        id: (await Auth.currentAuthenticatedUser()).username,
        fullName: fullName,
        preferredName: preferredName,
        location: location,
        hobbies: hobbies,
        hasChildren: hasChildren,
        hasPets: hasPets,
        hasGrandchildren: hasGrandchildren
      }
    })).then(output => {
      setRegistrationExists(true);
      console.log("output", output);
    }).catch(error => {
      console.error("error", error);
    }).finally(() => {
      setStatus('ready');
    })
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} loading={status === 'loading'}>
        <Form.Group>
          <label>Nombre completo</label>
          <input value={fullName} placeholder="Nombre completo" onChange={(e) => setFullName(e.target.value)}/>
        </Form.Group>
        <Form.Group>
          <label>Comó te gusta que te llamen?</label>
          <input value={preferredName} onChange={(e) => setPreferredName(e.target.value)}/>
        </Form.Group>
        <Form.Group inline>
          <label>Género</label>
          <Form.Field>
            <Radio
              label='Masculino'
              name='radioGroup'
              value='MALE'
              checked={gender === 'MALE'}
              onChange={(e) => setGender('MALE')}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Femenino'
              name='radioGroup'
              value='FEMALE'
              checked={gender === 'FEMALE'}
              onChange={(e) => setGender('FEMALE')}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='Otro'
              name='radioGroup'
              value='OTHER'
              checked={gender === 'OTHER'}
              onChange={(e) => setGender('OTHER')}
            />
          </Form.Field>
        </Form.Group>


        <Form.Group>
          <label>Fecha de Nacimiento</label>
        </Form.Group>
        <Form.Group>
          <label>Donde vives?</label>
          <input value={location} onChange={(e) => setLocation(e.target.value)}
                 placeholder="País / Región o Provincia / Comuna / Barrio"/>
        </Form.Group>
        <Form.Group>
          <label>Que te gusta hacer en tu tiempo libre</label>
          <textarea value={hobbies} onChange={(e) => setHobbies(e.target.value)}/>
        </Form.Group>

        <Form.Group inline>
          <label>Tienes Mascotas</label>
          <Form.Radio
            label="Sí"
            value="yes"
            checked={hasPets}
            onChange={() => setHasPets(!hasPets)}
          />
          <Form.Radio
            label="No"
            value="no"
            checked={!hasPets}
            onChange={() => setHasPets(!hasPets)}
          />
        </Form.Group>

        <Form.Group inline>
          <label>Tienes hijos</label>
          <Form.Radio
            label="Sí"
            value="yes"
            checked={hasChildren}
            onChange={() => setHasChildren(!hasChildren)}
          />
          <Form.Radio
            label="No"
            value="no"
            checked={!hasChildren}
            onChange={() => setHasChildren(!hasChildren)}
          />
        </Form.Group>

        {hasChildren && (
          <>
            <Form.Group inline>
              <label>Tienes nietos</label>
              <Form.Radio
                label="Sí"
                value="yes"
                checked={hasGrandchildren}
                onChange={() => setHasGrandchildren(!hasGrandchildren)}
              />
              <Form.Radio
                label="No"
                value="no"
                checked={!hasGrandchildren}
                onChange={() => setHasGrandchildren(!hasGrandchildren)}
              />
            </Form.Group>
          </>
        )}

        <label>A quien deseas enviar tus historias?</label>

        {suscriptors.map((suscriptor, index) => {
          return (
            <Form.Group>
              <Form.Field>
                <label>Nombre</label>
                <label>Email</label>
              </Form.Field>
            </Form.Group>
          )
        })}


        <Button type='submit' disabled={status === 'saving'}>
          {(status === 'saving') ? 'Guardando...' : 'Guardar'}
        </Button>
      </Form>
    </Container>
  );
}
