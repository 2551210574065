/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deliverGift = /* GraphQL */ `
  mutation DeliverGift($author_email: String!, $author_name: String!) {
    deliverGift(author_email: $author_email, author_name: $author_name)
  }
`;
export const createQuestionCategory = /* GraphQL */ `
  mutation CreateQuestionCategory(
    $input: CreateQuestionCategoryInput!
    $condition: ModelQuestionCategoryConditionInput
  ) {
    createQuestionCategory(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      question {
        nextToken
      }
    }
  }
`;
export const updateQuestionCategory = /* GraphQL */ `
  mutation UpdateQuestionCategory(
    $input: UpdateQuestionCategoryInput!
    $condition: ModelQuestionCategoryConditionInput
  ) {
    updateQuestionCategory(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      question {
        nextToken
      }
    }
  }
`;
export const deleteQuestionCategory = /* GraphQL */ `
  mutation DeleteQuestionCategory(
    $input: DeleteQuestionCategoryInput!
    $condition: ModelQuestionCategoryConditionInput
  ) {
    deleteQuestionCategory(input: $input, condition: $condition) {
      id
      title
      createdAt
      updatedAt
      question {
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      fullName
      gender
      preferredName
      birthdate
      location
      hobbies
      hasPets
      hasChildren
      hasGrandchildren
      pets {
        name
      }
      children {
        name
        alive
        biological_child
      }
      grandchildren {
        name
        alive
        biological_child
      }
      suscriptors {
        name
        email
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      fullName
      gender
      preferredName
      birthdate
      location
      hobbies
      hasPets
      hasChildren
      hasGrandchildren
      pets {
        name
      }
      children {
        name
        alive
        biological_child
      }
      grandchildren {
        name
        alive
        biological_child
      }
      suscriptors {
        name
        email
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      fullName
      gender
      preferredName
      birthdate
      location
      hobbies
      hasPets
      hasChildren
      hasGrandchildren
      pets {
        name
      }
      children {
        name
        alive
        biological_child
      }
      grandchildren {
        name
        alive
        biological_child
      }
      suscriptors {
        name
        email
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $input: CreateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    createStory(input: $input, condition: $condition) {
      id
      title
      status
      book_id
      question_id
      week_number
      s3EmailPath
      author_id
      subscribers_ids
      rawText
      formattedText
      imageUrl
      createdAt
      updatedAt
      version
      book {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $input: UpdateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    updateStory(input: $input, condition: $condition) {
      id
      title
      status
      book_id
      question_id
      week_number
      s3EmailPath
      author_id
      subscribers_ids
      rawText
      formattedText
      imageUrl
      createdAt
      updatedAt
      version
      book {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory(
    $input: DeleteStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    deleteStory(input: $input, condition: $condition) {
      id
      title
      status
      book_id
      question_id
      week_number
      s3EmailPath
      author_id
      subscribers_ids
      rawText
      formattedText
      imageUrl
      createdAt
      updatedAt
      version
      book {
        id
        title
        current_week
        author_id
        createdAt
        updatedAt
      }
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBook = /* GraphQL */ `
  mutation CreateBook(
    $input: CreateBookInput!
    $condition: ModelBookConditionInput
  ) {
    createBook(input: $input, condition: $condition) {
      id
      title
      current_week
      author_id
      createdAt
      updatedAt
      stories {
        nextToken
      }
    }
  }
`;
export const updateBook = /* GraphQL */ `
  mutation UpdateBook(
    $input: UpdateBookInput!
    $condition: ModelBookConditionInput
  ) {
    updateBook(input: $input, condition: $condition) {
      id
      title
      current_week
      author_id
      createdAt
      updatedAt
      stories {
        nextToken
      }
    }
  }
`;
export const deleteBook = /* GraphQL */ `
  mutation DeleteBook(
    $input: DeleteBookInput!
    $condition: ModelBookConditionInput
  ) {
    deleteBook(input: $input, condition: $condition) {
      id
      title
      current_week
      author_id
      createdAt
      updatedAt
      stories {
        nextToken
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      title
      gender
      question_category_id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      title
      gender
      question_category_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      title
      gender
      question_category_id
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionDelivered = /* GraphQL */ `
  mutation CreateQuestionDelivered(
    $input: CreateQuestionDeliveredInput!
    $condition: ModelQuestionDeliveredConditionInput
  ) {
    createQuestionDelivered(input: $input, condition: $condition) {
      id
      question_id
      email
      message_id
      tracking_code
      book_id
      createdAt
      updatedAt
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateQuestionDelivered = /* GraphQL */ `
  mutation UpdateQuestionDelivered(
    $input: UpdateQuestionDeliveredInput!
    $condition: ModelQuestionDeliveredConditionInput
  ) {
    updateQuestionDelivered(input: $input, condition: $condition) {
      id
      question_id
      email
      message_id
      tracking_code
      book_id
      createdAt
      updatedAt
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteQuestionDelivered = /* GraphQL */ `
  mutation DeleteQuestionDelivered(
    $input: DeleteQuestionDeliveredInput!
    $condition: ModelQuestionDeliveredConditionInput
  ) {
    deleteQuestionDelivered(input: $input, condition: $condition) {
      id
      question_id
      email
      message_id
      tracking_code
      book_id
      createdAt
      updatedAt
      question {
        id
        title
        gender
        question_category_id
        createdAt
        updatedAt
      }
    }
  }
`;
export const createQuestionHint = /* GraphQL */ `
  mutation CreateQuestionHint(
    $input: CreateQuestionHintInput!
    $condition: ModelQuestionHintConditionInput
  ) {
    createQuestionHint(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionHint = /* GraphQL */ `
  mutation UpdateQuestionHint(
    $input: UpdateQuestionHintInput!
    $condition: ModelQuestionHintConditionInput
  ) {
    updateQuestionHint(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionHint = /* GraphQL */ `
  mutation DeleteQuestionHint(
    $input: DeleteQuestionHintInput!
    $condition: ModelQuestionHintConditionInput
  ) {
    deleteQuestionHint(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      author_email
      author_name
      client_id
      email_hash
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      author_email
      author_name
      client_id
      email_hash
      accepted
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      author_email
      author_name
      client_id
      email_hash
      accepted
      createdAt
      updatedAt
    }
  }
`;
