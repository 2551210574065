import * as React from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { useEffect, useState } from "react";
import {
  getGroups,
  isAuthenticated as CognitoIsAuthenticated,
} from "../CognitoHelper";

function Navigation() {
  const [groups, setGroups] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    getGroups().then((groups) => setGroups(groups));

    CognitoIsAuthenticated().then((authenticated) => {
      setIsAuthenticated(authenticated);
    });
  }, []);

  if (!isAuthenticated) {
    return null;
  }
  return (
    <div className="App">
      <nav>
        <Menu>
          <Menu.Item>
            <Link to="/">Home</Link>
          </Menu.Item>

          <Menu.Item>
            <Link to="/register">Formulario de registro</Link>
          </Menu.Item>

          {groups.includes("administrator") && (
            <Menu.Item>
              <Link to="/sendInvite">Invite</Link>
            </Menu.Item>
          )}

          {groups.includes("administrator") && (
            <Menu.Item>
              <Link to="/questions">Questions</Link>
            </Menu.Item>
          )}

          <Menu.Item>
            <Link to="/logout">Salir</Link>
          </Menu.Item>
        </Menu>
      </nav>
      <div style={{ marginBottom: "20px" }} />
    </div>
  );
}

export default Navigation;
