import {API, Auth, graphqlOperation} from "aws-amplify";
import {getUser} from "../graphql/queries";

export async function getRegistrationForm() {
  const response = await API.graphql(
    graphqlOperation(getUser, {
      id: (await Auth.currentAuthenticatedUser()).username
    })
  );
  return response.data.getUser;
}