import { Auth } from "aws-amplify";

export async function getGroups() {
  return Auth.currentSession()
    .then((session) => {
      const groups = session.getAccessToken().payload["cognito:groups"];
      if (groups) {
        return groups;
      }
      return [];
    })
    .catch(() => {
      console.log("user not authenticated");
      return [];
    });
}

export async function isAuthenticated() {
  return Auth.currentSession()
    .then((user) => {
      console.log("user authenticated", user);
      return true;
    })
    .catch(() => {
      return false;
    });
}
