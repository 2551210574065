import React, {useEffect, useState} from "react";
import {getRegistrationForm} from "./dao";
import {Redirect} from "react-router";

export default function Home() {
  const [status, setStatus] = useState("loading")

  useEffect(() => {
    getRegistrationForm().then((registration) => {
      if (!registration){
        setStatus("doesn't exist");
      }
    })
  })

  if (status === "doesn't exist"){
    console.log("no existe registro");
    return (
      <Redirect to="/register" />
    )
  } else {
    return <div>Home</div>;
  }

}
