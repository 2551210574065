import * as React from "react";
import { useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Button, Checkbox, Form, Select } from "semantic-ui-react";
import { createQuestion } from "../graphql/mutations";

export default function NewQuestionFrom(props) {
  const [question, setQuestion] = useState("");
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [questionCategory, setQuestionCategory] = useState();

  async function handleSubmit(evt) {
    evt.preventDefault();
    console.log("submit", question, male, female);

    const gender = [];
    if (male) {
      gender.push("MALE");
    }
    if (female) {
      gender.push("FEMALE");
    }

    const variables = {
      input: {
        title: question,
        gender,
        question_category_id: questionCategory,
      },
    };

    console.log("new query", variables);

    try {
      const newQuestion = await API.graphql(
        graphqlOperation(createQuestion, variables)
      );

      console.log("newQuestion", newQuestion.data.createQuestion);
      setQuestion("");
      props.onSubmission(newQuestion.data.createQuestion);
    } catch (e) {
      console.error("Error", e);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>Tipo de pregunta</label>
              <Select
placeholder="Selecciona tipo de pregunta" options={props.questionCategories}
          value={questionCategory}
                  onChange={(event, dropdownProps) => setQuestionCategory(dropdownProps.value)}
              />
      </Form.Field>
      <Form.Field>
        <label>
                  Nueva pregunta:
                    <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </label>
      </Form.Field>
      <Form.Field>
        <label>Género:</label>
              <Checkbox
          label="Femenino"
          checked={female}
          onChange={(e) => setFemale(!female)}
        />
        <Checkbox
          label="Masculino"
          checked={male}
          onChange={(e) => setMale(!male)}
        />
      </Form.Field>

      <Button type="submit" disabled={!(female || male)}>
              Guardar pregunta
            </Button>
    </Form>
  );
}
