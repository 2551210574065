import React from "react";
import { graphqlOperation, API } from "aws-amplify";
import InviteForm from "./InviteForm";
import { deliverGift } from "../graphql/mutations";

export default function Invite() {
  async function inviteCallBack({ author_email, author_name }) {
    console.log("callback");

    const variables = {
      author_email,
      author_name,
    };
    console.log("variables", variables);

    await API.graphql(graphqlOperation(deliverGift, variables))
      .then((ql) => {
        console.log("ql", ql);
      })
      .catch((onerror) => {
        console.error("Error enviando regalo", onerror);
      });
  }

  return (
    <div>
      <h1>Invite</h1>

      <InviteForm onSubmission={inviteCallBack} />
    </div>
  );
}
