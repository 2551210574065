import React, { useEffect, useState } from "react";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { isAuthenticated as CognitoIsAuthenticated } from "../CognitoHelper";

function Login(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    CognitoIsAuthenticated().then((authenticated) => {
      setIsAuthenticated(authenticated);
    });
  }, []);

  function handleAuthStateChange(newState) {
    console.log("newAuthState", newState);
    if (newState === "signedin") {
      window.location.reload(true);
    }
  }

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: "login" },
        }}
      />
    );
  }
  return (
    <AmplifyAuthenticator
      usernameAlias="email"
      handleAuthStateChange={handleAuthStateChange}
    >
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
    </AmplifyAuthenticator>
  );
}

export default withRouter(Login);
