import React from "react";

class InviteForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            status: "initial"
        }
    }

    handleSubmit = (evt) => {
        evt.preventDefault();
        this.setState({status: 'sending'})
        this.props.onSubmission({author_name: this.state.name, author_email: this.state.email}).then(() => {
            this.setState({status: 'done'});
        });
    };

    render() {
        switch (this.state.status) {
            case "initial":
                return (
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <label>
                                Nombre de la persona a la que quieres hacer el regalo:
                                <input
                                    type="text"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({name: e.target.value})}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Email
                                <input
                                    type="text"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value})}
                                />
                            </label>
                        </div>
                        <input type="submit" value="Submit"
                               disabled={this.state.email === "" || this.state.name === ""}/>
                    </form>
                );
            case "sending":
                return (
                    <div>Enviando...</div>
                )
            default:
                return (
                    <div>Regalo enviado</div>
                )
        }
    }


}

export default InviteForm;