import * as React from "react";
import { useState } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";

export default function NewQuestionCategory(props) {
  const [questionCategory, setQuestionCategory] = useState("");

  function handleQuestionCategory() {
    props.onSubmission(questionCategory);
    setQuestionCategory("");
  }

  return (
    <Form onSubmit={handleQuestionCategory}>
      <Form.Group inline>
        <label>
          Nueva categoría:
          <input
            type="text"
            value={questionCategory}
            onChange={(e) => setQuestionCategory(e.target.value)}
          />
        </label>
      </Form.Group>

      <Button type="submit" disabled={questionCategory.length === 0}>
        Guardar categoría
      </Button>
    </Form>
  );
}
