import React from "react";
import { withRouter } from "react-router-dom";

function InviteCode(props) {
  const { inviteCode } = props.match.params;

  return <div>Invite Code{inviteCode}</div>;
}

export default withRouter(InviteCode);
