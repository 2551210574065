import React from "react";
import "./App.css";
import Amplify from "aws-amplify";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import awsconfig from "./aws-exports";
import Navigation from "./components/Navigation";
import Invite from "./components/Invite";
import RegistrationForm from "./components/RegistrationForm";
import Question from "./components/Question";
import Home from "./components/Home";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import InviteCode from "./components/InviteCode";
import Logout from "./components/Logout";

Amplify.configure(awsconfig);

function App() {
  return (
    <BrowserRouter>
      <Navigation/>
      <Switch>
        <Route path="/invite/:inviteCode" component={InviteCode}/>
        <PrivateRoute path="/sendInvite" component={Invite}/>
        <PrivateRoute path="/register" component={RegistrationForm}/>
        <PrivateRoute path="/questions" component={Question}/>
        <Route path="/logout" component={Logout}/>
        <Route path="/login" component={Login}/>
        <PrivateRoute path="/" component={Home}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
