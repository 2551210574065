import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated as CognitoIsAuthenticated } from "../CognitoHelper";

function PrivateRoute({ children, ...rest }) {
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    CognitoIsAuthenticated().then((authenticated) => {
      if (authenticated) {
        setStatus("authenticated");
      } else {
        setStatus("unauthenticated");
      }
    });
  }, []);

  switch (status) {
    case "loading":
      return null;
    case "authenticated":
      return <Route {...rest} render={() => children} />;

    default:
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: "login" },
          }}
        />
      );
  }
}

export default PrivateRoute;
